@import '../../../styles/customMediaQueries.css';

.root {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
    flex-shrink: 0;
    margin-right: 22px;
    padding: 3px 0;
  
    @media (--viewportMedium) {
      padding: 4px 0;
    }
  
    @media (--viewportLarge) {
      flex-direction: row;
      margin-right: unset;
      padding: 0;
    }
  }

  .price {
    composes: h4 from global;
    font-weight: var(--fontWeightBold);
  
    color: var(--marketplaceColor);
    margin: 0 0 0 24px;
  
    @media (--viewportMedium) {
      margin: 0;
      padding: 0;
    }
  }
  

  .perUnit {
    /* Font */
    composes: h4 from global;
    font-weight: var(--fontWeightBold);
  
    color: var(--marketplaceColor);
    padding: 0;
  
    margin-top: 0;
    margin-bottom: 0;
  
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  
    @media (--viewportLarge) {
    }
  }