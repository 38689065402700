.modal {
  z-index: 110;
}

.content {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 29px;

  @media (--viewportMedium) {
    margin-top: 5vh;
    margin-bottom: 7.5vh;
    min-height: 700px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}


/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}