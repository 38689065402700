.root {
  border: 2px dashed black;
  border-radius: 8px;
  width: 100%;
  height: 180px;
  background-color: var(--colorGrey75);
  margin-bottom: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
  transition: var(--transitionStyleButton);
  &:hover {
    border-color: var(--colorGrey300);
  }
}

.icon {
  display: block;
  animation: moveUpDown 0.5s infinite alternate;
}

.dragAccepted {
  border: 2px solid var(--colorBorderDragDropAccepted);
  background-color: var(--colorBackgroundDragDropAccepted);
}

.dragRejected {
  border: 2px solid var(--colorBorderDragDropRejected);
  background-color: var(--colorBackgroundDragDropRejected);
}

.chooseFileText {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.chooseFile {
  composes: marketplaceSmallFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 5px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
  }
}

.dragDropInstruction {
  composes: marketplaceSmallFontStyles from global;
  color: var(--colorGrey500);

  text-align: center;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.errorText {
  text-align: left;
  color: var(--colorFail);
  margin-bottom: 24px;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}